import { BaseSlice } from 'features/baseSlice';
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { Deserializer } from 'jsonapi-serializer'

const deserialize = async (data) => {
  const jsonapi = new Deserializer({ keyForAttribute: 'snake_case' });
  return await jsonapi.deserialize(data)
};

export const getPayrollData = createAsyncThunk(
  '@PayrollData/getPayrollData', async (id) => await axios.get(`/api/v1/payrolls/${id}`).then(async ({ data }) => {
    try {
      let newData = await deserialize(data);
    
      //  newData and payroll_items exist
      if (!newData || !Array.isArray(newData.payroll_items)) {
        console.error("Warning: Invalid data structure - payroll_items is missing or not an array");
        return { payroll_items: [] }; // Return an empty array to avoid further errors
      }
    
      newData.payroll_items = newData.payroll_items.map((item) => {
        if (!item.employee || !item.employee.id) {
          console.warn("Warning: Missing employee data for payroll item:", item);
          return { ...item, id: null }; 
        }
    
        return { ...item, id: item.employee.id };
      });
    
      return newData;
    } catch (error) {
      console.error("Error processing payroll data:", error.message);
      return { payroll_items: [] }; 
    }
  })    
);

export const updatePayrollData = createAsyncThunk(
  '@PayrollData/updatePayrollData', async ({ id, ...params } ) => await axios.put(`/api/v1/payrolls/${id}`, params).then(async ({ data }) => {
    return await deserialize(data)
  })
);

export const getPayrollMessages = createAsyncThunk(
  '@PayrollData/getPayrollMessages', async (params) => { return await axios.get('/api/v1/payrolls/get_payroll_messages', { params }) }
);

export const getEmployeesToAdd = createAsyncThunk(
  '@PayrollData/getEmployeesToAdd', async (id) => { return await axios.get(`/api/v1/payrolls/${id}/available_employees_to_add`) }
);

export const addItemsToPayrollData = createAsyncThunk(
  '@PayrollData/addItemsToPayrollData', async ({ id, ...params } ) => await axios.put(`/api/v1/payrolls/${id}/add_items`, params)
);

export const resetUpdateJobId = createAsyncThunk(
  '@PayrollData/resetUpdateJobId', async () => { return true }
);

const initialState = {
  rows: [],
  metaData: {},
  isLoading: false,
  error: false,
  selected: {},
  payrollMessages: [],
  employeesToAdd: [],
  addEmployeesStatus: 'idle',
  payrollUpdateJobId: null
};

const bslice = BaseSlice(
  'PayrollData',
  '/api/v1/payrolls',
  initialState,
  null,
  {
    setDefaults: (state) => {}
  },
  {
    [getPayrollData.pending]: (state, data) => {
      state.isLoading = true;
      state.selected = {};
      state.payrollUpdateJobId = null;
    },
    [getPayrollData.fulfilled]: (state, data) => {
      state.isLoading = false;
      state.selected = data.payload;
    },
    [updatePayrollData.pending]: (state, data) => {
      state.isLoading = true;
    },
    [updatePayrollData.fulfilled]: (state, data) => {
      state.isLoading = false;
      state.is_updated = true;
      state.payrollUpdateJobId = data.payload.id;
    },
    [getPayrollMessages.fulfilled]: (state, { payload: { data } } ) => {
      state.payrollMessages = data.payroll_messages;
    },
    [getEmployeesToAdd.fulfilled]: (state, { payload: { data: { data } } }) => {
      state.employeesToAdd = data;
    },
    [addItemsToPayrollData.pending]: (state) => {
      state.addEmployeesStatus = 'started';
    },
    [addItemsToPayrollData.fulfilled]: (state) => {
      state.addEmployeesStatus = 'success';
    },
    [addItemsToPayrollData.rejected]: (state) => {
      state.addEmployeesStatus = 'failed';
    },
    [resetUpdateJobId.fulfilled]: (state) => {
      state.payrollUpdateJobId = null;
    }
  }
);
const { reducer, List, Delete, slice: PayrollDataSlice } = bslice;

export const createPayrollDataReq = (params) => axios.post('/api/v1/payrolls', params);

export const listPayrollData = List;
// export const getPayrollData = Show;
// export const createPayrollData = Create;
export const deletePayrollData = Delete;
export const { setDefaults } = PayrollDataSlice.actions;

export default reducer;
