import { BaseSlice, setRelationships } from 'features/baseSlice';
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const updateEmployeeImage = createAsyncThunk(
  "@Employees/updateImage", async ({ id, ...params }, thunkApi) => {
    const formData = new FormData();
    formData.append("image", params.image);
    return await axios.put(`/api/v1/employees/${id}/update_image`, formData);
  }
);

export const updatePayrollEmployeeAttribute = createAsyncThunk(
  "@Employees/updatePayrollEmployeeAttribute", async ({ id, ...params }, thunkApi) => {
    return await axios.put(`/api/v1/employees/${id}/update_payroll_attributes`, params.data);
  }
)

export const mergeEmployees = createAsyncThunk(
  "@Employees/mergeEmployees", async(params, thunkApi) => {
    try {
      return await axios.post(`/api/v1/employees/merge_employees`, params);
    } catch (err) {
      return thunkApi.rejectWithValue({
        ...err.response.data,
        status: err.response.status,
      });
    }
  }
);

export const setIsMerged = createAsyncThunk(
  "@Employees/setIsMerged", async(newValue, thunkApi) => {
    return { isMerged: newValue };
  }
);

export const setIsMergeFailed = createAsyncThunk(
  "@Employees/setIsMergeFailed", async(newValue, thunkApi) => {
    return { isMergeFailed: newValue };
  }
);

export const restoreEmployee = createAsyncThunk(
  "@Employees/restoreEmployee", async(id, thunkApi) => {
    try {
      return await axios.put(`/api/v1/employees/${id}/restore_employee`);
    } catch (err) {
      return thunkApi.rejectWithValue({
        ...err.response.data,
        status: err.response.status,
      });
    }
  }
);

export const getEmployeesForPayroll = createAsyncThunk(
  "@Employees/getEmployeesForPayroll", async(params, thunkApi) => {
    try {
      return await axios.get('/api/v1/employees/for_payroll', { params });
    } catch (err) {
      return thunkApi.rejectWithValue({
        ...err.response.data,
        status: err.response.status,
      });
    }
  }
);

const bslice = BaseSlice(
  'Employees',
  '/api/v1/employees',
  {
    rows: [],
    metaData: {},
    isLoading: false,
    error: false,
    selected: {},
    isMerged: false,
    isMergeFailed: false,
    mergeFailReason: '',
    payrollEmployees: []
  },
  null,
  {
    setDefaults: (state) => {
      state.employee = null;
    }
  },
  {
    [updatePayrollEmployeeAttribute.pending]: (state) => {
      state.isLoading = true;
    },
    [updatePayrollEmployeeAttribute.fulfilled]: (state, { payload: { data: { data } } }) => {
      state.is_created = true;
      state.isLoading = false;
    },
    [updatePayrollEmployeeAttribute.rejected]: (state) => {
      state.isLoading = false;
    },
    [updateEmployeeImage.fulfilled]: (state, { payload: { data: { data } } }) => {
      const { rows, selected } = state;
      state.rows = rows.map(row => (row.id === data.id) ? data : row);
      state.selected = { ...selected, ...data.attributes };
    },
    [mergeEmployees.pending]: (state) => {
      state.isLoading = true;
    },
    [mergeEmployees.fulfilled]: (state, { payload: { data: { data } } }) => {
      state.isMerged = true;
      state.isLoading = false;
    },
    [mergeEmployees.rejected]: (state, action) => {
      state.isMerged = false;
      state.isMergeFailed = true;
      state.mergeFailReason = action.payload[0];
      state.isLoading = false;
    },
    [setIsMerged.fulfilled]: (state, { payload: { isMerged }}) => {
      state.isMerged = isMerged;
    },
    [setIsMergeFailed.fulfilled]: (state, { payload: { isMergeFailed } }) => {
      state.isMergeFailed = isMergeFailed;
    },
    [restoreEmployee.pending]: (state) => {
      state.isLoading = true;
    },
    [restoreEmployee.fulfilled]: (state) => {
      state.isLoading = false;
      state.reload = true;
      state.status = 'edited';
    },
    [restoreEmployee.rejected]: (state) => {
      state.isLoading = false;
    },
    [getEmployeesForPayroll.pending]: (state) => {
      state.isLoading = true;
    },
    [getEmployeesForPayroll.fulfilled]: (state, { payload: { data: { meta, data, included = [] } } }) => {
      state.payrollEmployees = data;
      state.relationships = setRelationships(state, included)
      state.metaData = meta;
      state.reload = false
      state.is_created = false;
      state.isLoading = false;
      if (state.status === 'edited') {
          state.status = 'reloaded'

      } else {
          state.status = 'new'
      }
    },
    [getEmployeesForPayroll.rejected]: (state) => {
      state.isLoading = false;
    }
  }
);
const { reducer, List, Show, Update, Create, Delete, slice: EmployeeSlice } = bslice;

export const getEmployees = List;
export const getEmployee = Show;
export const createEmployee = Create;
export const deleteEmployee = Delete;
export const updateEmployee = Update;

export const { setDefaults } = EmployeeSlice.actions;

export default reducer;
